import React from 'react'
import { PluginProps } from '@graphcommerce/next-config'
import Script from 'next/script'

export const component = 'GoogleTagManagerScript'
export const exported = '@graphcommerce/googletagmanager/components/GoogleTagManagerScript'

function GoogleTagManagerScriptPlugin(props: PluginProps) {
  const cmpGuid = import.meta.graphCommerce.cmpDomainGroupUid
  const { Prev, ...rest } = props
  const generateConsentScript = () => {
    const fullConsent = {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 500,
    }

    const fullConsentString = JSON.stringify(fullConsent)
    return `
        gtag('consent', 'default', ${fullConsentString});
      `
  }

  return (
    <React.Fragment>
      <Prev {...rest} />
      {/* Ensure Google Tag Manager Script is loaded */}
      <Script id='gtag' strategy='afterInteractive'>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          ${generateConsentScript()}
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_passthrough", false);
      `}</Script>
      <Script
        id='Cookiebot'
        src='https://consent.cookiebot.com/uc.js'
        data-cbid={`${cmpGuid}`}
        type='text/javascript'
      />
      <Script
        id='CookieDeclaration'
        src={`https://consent.cookiebot.com/${cmpGuid}/cd.js}`}
        type='text/javascript'
        async
      />
    </React.Fragment>
  )
}

export const Plugin = GoogleTagManagerScriptPlugin
